<template>
  <div style="padding: 20px">
    <div class="query-list" style="padding-bottom: 20px">
      <el-form style="padding: 20px 20px 0" :model="queryForm" ref="queryForm" inline>
        <el-form-item label="艺术家昵称">
          <el-input
            v-model="queryForm.art_nick_name"
            placeholder="请输入艺术家昵称"
            @keyup.enter.native="handleClickSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="艺术家UCID">
          <el-input
            v-model="queryForm.art_ucid"
            placeholder="请输入艺术家UCID"
            @keyup.enter.native="handleClickSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="项目名称">
          <el-input
            v-model="queryForm.project_name"
            placeholder="请输入项目名称"
            @keyup.enter.native="handleClickSearch"
          ></el-input>
        </el-form-item>

        <el-form-item label="审核状态">
          <el-select v-model="queryForm.approval_status" @change="handleClickSearch">
            <el-option label="未审核" value="0"> </el-option>
            <el-option label="审核中" value="1"> </el-option>
            <el-option label="已发布" value="2"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="handleClickSearch">查询</el-button>
          <el-button type="info" @click="handleClickReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-table :data="list" border stripe row-key="user_id">
      <el-table-column label="项目标题" prop="product_title"> </el-table-column>
      <el-table-column label="艺术家信息" prop="artist_info"> </el-table-column>
      <el-table-column label="项目类型" prop="project_type">
        <template slot-scope="scope">
          <div>{{ ProjectType[scope.row.project_type] }}</div>
        </template>
      </el-table-column>
      <el-table-column label="已筹集/目标金额" prop="amount_raised">
        <template slot-scope="scope">
          <div>{{ scope.row.amount_raised + "/" + scope.row.target_amount }}</div>
        </template>
      </el-table-column>
      <el-table-column label="支持人数" prop="supporters_number"> </el-table-column>
      <el-table-column label="点赞数" prop="likes"> </el-table-column>
      <el-table-column label="项目阶段" prop="project_stage">
        <template slot-scope="scope">
          <div>{{ ProjectStage[scope.row.project_stage] }}</div>
        </template>
      </el-table-column>
      <el-table-column label="审核状态" prop="approval_status">
        <template slot-scope="scope">
          <div>{{ ApprovalStatus[scope.row.approval_status] }}</div>
        </template>
      </el-table-column>
      <el-table-column label="返利比例" prop="cashback_ratio"> </el-table-column>
      <el-table-column label="待返利金额" prop="to_be_rebate_amount"> </el-table-column>

      <el-table-column label="操作" width="260" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.approval_status < 2"
            size="mini"
            type="primary"
            @click="handleInfo(scope.row, true)"
          >
            预览
          </el-button>
          <el-button
            v-if="scope.row.approval_status == 1"
            size="mini"
            type="primary"
            @click="handleEdit(scope.row)"
          >
            审批
          </el-button>
          <el-button
            v-if="scope.row.project_stage < 2 && scope.row.approval_status == 2"
            size="mini"
            type="danger"
            @click="handleAbort(scope.row)"
          >
            终止
          </el-button>
          <el-button
            v-if="scope.row.approval_status == 2"
            size="mini"
            type="primary"
            @click="handleInfo(scope.row)"
          >
            查看
          </el-button>
          <el-button
            v-if="scope.row.project_stage == 2"
            size="mini"
            type="primary"
            @click="handleNext"
          >
            划拨资金
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 20px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog
      title="项目审批"
      :visible.sync="reviewDialog"
      width="30%"
      append-to-body
      @close="visible = false"
    >
      <div>项目：{{ currentProductName }}</div>
      <div style="padding-bottom: 20px">是否确认审批通过？</div>
      <div>
        <el-button size="mini" type="primary" @click="handleInfo(currentProject, true)">
          项目预览
        </el-button>
        <el-button size="mini" type="success" @click="handleApproval(currentProjectId, '0')">
          审批通过
        </el-button>
        <el-button size="mini" type="danger" @click="handleApproval(currentProjectId, '1')">
          驳回
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getCrowdfundingProjectList, setCrowdfundingProject } from "@/api/nft.js";
import { ProjectType, ProjectStage, ApprovalStatus } from "@/utils/helper.js";
import config from "@/configs/index";

export default {
  data() {
    return {
      page: 1,
      pageSize: 20,
      total: 0,
      queryForm: {
        // art_nick_name: "",
        // art_ucid: "",
        // project_name: "",
        // approval_status: "",
      },
      ProjectType,
      ApprovalStatus,
      ProjectStage,
      list: [],
      reviewDialog: false,
      currentProject: {},
      currentProjectId: "",
      currentProductName: "",
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    handleEdit(row) {
      this.reviewDialog = true;
      this.currentProject = row;
      this.currentProjectId = row.crowdfunding_main_id;
      this.currentProductName = row.product_title;
    },
    handleNext() {
      this.$message({
        message: "当前未到提取项目资金时间",
        duration: 1500,
        type: "info",
      });
    },
    handleInfo(row, isPreview = false) {
      // todo 预览/查看
      console.log(row.crowdfunding_main_id);
      this.reviewDialog = false;
      if (isPreview) {
        window.open(`${config.crowdH5}/product/${row.crowdfunding_main_id}?type=preview`);
      } else {
        window.open(`${config.crowdH5}/product/${row.crowdfunding_main_id}`);
      }
    },
    //  未审核：项目未提交审核，或被驳回后  0
    //  审核中：已提交审核并支付， 1
    //  已发布：审核通过，项目正常展示  2
    async handleApproval(id, status) {
      // 审批通过0 驳回1 终止2
      try {
        const params = {
          crowdfunding_main_id: id,
          status: +status,
        };
        const { data } = await setCrowdfundingProject(params);
        this.reviewDialog = false;
        this.fetchData();
      } catch (error) {}
    },
    handleAbort(row) {
      this.$confirm(
        `项目：【${row.product_title}】是否终止项目？\n未开始的项目将直接停止，众筹中的项目将变成众筹失败并退款，请慎重操作。`,
        "项目终止",
        {
          confirmButtonText: "项目终止",
          cancelButtonText: "取消",
          type: "warning",
        },
      )
        .then(() => {
          // console.log("项目终止", row.crowdfunding_main_id);
          this.handleApproval(row.crowdfunding_main_id, "2");
        })
        .catch((error) => {});
    },
    getPatamsFromQueryForm() {
      let params = {};
      Object.keys(this.queryForm).forEach((key) => {
        if (this.queryForm[key]) {
          params[key] = this.queryForm[key];
        }
      });
      return params;
    },
    async fetchData() {
      try {
        const params = {
          // ...this.getPatamsFromQueryForm(),
          filter: this.queryForm,
          page: this.page,
          page_size: this.pageSize,
        };
        const { data } = await getCrowdfundingProjectList(params);
        const { total = 0, items = [] } = data?.data;
        this.total = total;
        this.list = [...items];
      } catch (error) {}
    },
    handleClickSearch() {
      this.page = 1;
      this.pageSize = 20;
      this.fetchData();
    },
    handleClickReset() {
      this.resetQuery();
      this.fetchData();
    },
    resetQuery() {
      this.page = 1;
      this.pageSize = 20;
      this.queryForm = {};
    },
    handleCurrentChange(page) {
      this.page = page;
      this.fetchData();
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.fetchData();
    },
    handleStatusChange() {
      this.page = 1;
      this.pageSize = 20;
      this.fetchData();
    },
  },
};
</script>
